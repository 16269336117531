import React from "react";

import NewsPage from "../ui/news/NewsPage";
import Layout from "../components/Layout";

const NewsPageTemplate = (props) => {
  const { pageContext } = props;
  const { newsItem } = pageContext;

  const newsPage = <NewsPage newsItem={newsItem} />;

  return <Layout children={newsPage} />;
};

export default NewsPageTemplate;
